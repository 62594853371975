
// Libs
import { db } from "@/firebase-init"
import { Util } from "@/helpers"
import { string } from "@/string"
import firebase from "firebase/app"
import has from "lodash/has"

// Components
import Gallery from "./Gallery.vue"
import TaskCard from "./Task.vue"
import CRDrawer from "@/components/EditCraftRecord.vue"
import ConfirmDialog from "@/components/ConfirmDialog.vue"

// Models
import {
  CraftType,
  SiteKeyUsers,
  Location,
  SiteKey,
  SiteKeyCompany,
  Event,
  EventTypes,
} from "@/models/models"
import { Tasks } from "@/models/task"

export default {
  name: "MultiCraftRecord",
  data() {
    return {
      theme: process.env.VUE_APP_THEME,
      snackbar: false,
      snackbarText: "",
      snackBarColor: "",
      mainView: "pictures",
      selectedOpenTask: null,
      selectedClosedTask: null,
      selectedOtherTask: null,
      editCraftRecord: false,
      loadingTaskList: true,
      siteKeyData: {} as SiteKey,
      companies: Array<SiteKeyCompany>(),
      dynValues: null,
      dynCraftDetails: {},
      loading: false,
      confirmDialog: {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      },
    }
  },
  mounted() {
    this.loadViewData()
  },
  // Stop listeners/remove saved data upon view destroy
  beforeDestroy(): void {
    this.$store.commit("firetableModule/removeCraftRecord")
    this.$store.commit("firetableModule/removeCraftRecordTaskList")
    this.$store.commit("firetableModule/unsubscribeCraftRecordListener")
    this.$store.commit("firetableModule/unsubscribePublicEventListener")
    this.$store.commit("firetableModule/unsubscribeCompanyEventListener")
    this.$store.commit("firetableModule/unsubscribeCraftRecordTasklistListener")
  },
  asyncComputed: {
    CRData: {
      async get() {
        const { craftRecord } = this.$store.state.firetableModule
        return craftRecord ? await this.buildRecordData() : {}
      },
      default: {},
    },
    tasks: {
      async get() {
        const { craftRecordTaskList, multiCraftRecordTasks } =
          this.$store.state.firetableModule
        return craftRecordTaskList
          ? await this.buildTaskList(multiCraftRecordTasks)
          : []
      },
      default: [],
    },
    closedTasks: {
      async get() {
        const tasks = this.tasks
          .filter((t) => t.taskStatus === 90)
          .sort((a, b) => a.taskStatus - b.taskStatus)
          .sort((a, b) => {
            if (a.timestampCreated && b.timestampCreated) {
              const aSecs = a.timestampCreated.seconds
              const bSecs = b.timestampCreated.seconds
              return bSecs - aSecs
            }
          })
        this.highlightCurrentTask(tasks, "closed")
        return tasks
      },
      default: [],
    },
    openTasks: {
      async get() {
        const tasks = this.tasks
          .filter((t) => t.taskStatus !== 90)
          .sort((a, b) => a.taskStatus - b.taskStatus)
          .sort((a, b) => {
            if (a.timestampCreated && b.timestampCreated) {
              const aSecs = a.timestampCreated.seconds
              const bSecs = b.timestampCreated.seconds
              return bSecs - aSecs
            }
          })
        this.highlightCurrentTask(tasks, "open")
        return tasks
      },
      default: [],
    },
  },
  computed: {
    multiCraftRecord() {
      return this.$store.state.firetableModule.multiCraftRecord
    },
    multiCraftRecordParentRecords() {
      return this.$store.state.firetableModule.multiCraftRecordParentRecords
    },
    multiCraftRecordTasks() {
      return this.$store.state.firetableModule.multiCraftRecordTasks
    },
    numMultiCrafts() {
      if (Array.isArray(this.multiCraftRecordParentRecords)) {
        return this.multiCraftRecordParentRecords.length
      }
      return null
    },
    numMultiTasks() {
      if (Array.isArray(this.multiCraftRecordTasks)) {
        return this.multiCraftRecordTasks.length
      }
      return null
    },
    defaultSiteKey(): string | null {
      const { rootUserData } = this.$store.state.firetableModule
      return rootUserData ? rootUserData.defaultSiteKey : null
    },
    eventList(): Array<Event> | [] {
      const { eventList } = this.$store.state.firetableModule
      return eventList
        ? eventList
            .filter((e) => e.craftRecordID === this.CRData.refPath)
            .sort((a, b) => {
              const aSecs = a.timestampCreated.seconds
              const bSecs = b.timestampCreated.seconds
              return bSecs - aSecs
            })
        : []
    },
    siteKeyUserPermissionData(): any | null {
      return this.$store.state.firetableModule.siteKeyUserPermissionData
    },
    multiCraftRecordID(): string | null {
      const { craftRecord } = this.$store.state.firetableModule
      const multiCraftRecordID = craftRecord?.multiCraftRecordID
      if (typeof multiCraftRecordID === "string") {
        this.$store.dispatch(
          "firetableModule/queryMultiCraftRecord",
          multiCraftRecordID
        )
        return multiCraftRecordID
      }
      return null
    },
    siteClassification(): string | null {
      const siteKey = this.$store.state.firetableModule.siteKey
      return siteKey.customizations.siteClassification
    },
  },
  watch: {
    defaultSiteKey(newValue: string | null) {
      if (newValue) this.loadViewData()
    },
    siteKeyUserPermissionData(newValue: string | null) {
      if (newValue) this.loadViewData()
    },
    multiCraftRecord(newValue: object | null) {
      if (newValue) {
        this.getMultiCraftRecordParentRecords()
        this.getMultiCraftRecordTasks()
      }
    },
  },
  methods: {
    loadViewData() {
      if (!this.defaultSiteKey || !this.siteKeyUserPermissionData) return
      const { id } = this.$route.params
      const craftIDpath = `siteKeys/${this.defaultSiteKey}/parentRecords/${id}`
      this.$store.commit("firetableModule/startLoading")
      this.$store.dispatch("firetableModule/listenEvents")
      this.$store.dispatch("firetableModule/listenCraftRecord", id)
      this.$store.dispatch(
        "firetableModule/listenCraftRecordTaskList",
        craftIDpath
      )
      this.$store.commit("firetableModule/stopLoading")
    },

    highlightCurrentTask(tasks, type) {
      // Auto open task
      const { taskID } = this.$route.params
      const index = tasks.findIndex((t) => t.id === taskID)
      if (index === -1) return
      if (type === "closed") {
        this.selectedClosedTask = index
        this.selectedOpenTask = null
      } else {
        this.selectedOpenTask = index
        this.selectedClosedTask = null
      }
    },

    getEventString(type) {
      return EventTypes.getEventTypeString(type)
    },

    getEventIcon(type) {
      return EventTypes.getEventTypeIconDetails(type)
    },

    async getCompanies() {
      const { rootUserData, siteKeyUserPermissionData, craftRecord } =
        this.$store.state.firetableModule
      if (!rootUserData || !siteKeyUserPermissionData || !craftRecord) return
      this.companies = await Util.getSiteKeyCompanies({
        db,
        defaultSiteKey: rootUserData.defaultSiteKey,
        userPermissions: siteKeyUserPermissionData,
      })
      this.companies = this.companies.filter((record: SiteKeyCompany) =>
        record.craftTypes.includes(parseInt(craftRecord.craftType, 10))
      )
    },

    storeDynamicValues(values) {
      this.dynValues = values
    },

    formatDate: (data) => Util.formatDate(data),

    setEditCR(edit) {
      this.editCraftRecord = edit
    },

    async buildRecordData() {
      if (!this.$store.state.firetableModule.rootUserData) return

      const { craftRecord } = this.$store.state.firetableModule

      // Get User details
      const { defaultSiteKey } = this.$store.state.firetableModule.rootUserData
      const userDetail: SiteKeyUsers | null = await Util.getSiteKeyUserDetail(
        db,
        defaultSiteKey,
        craftRecord.createdBy
      )
      const { assetID } = craftRecord
      const assetDetail = assetID
        ? await Util.getAssetDetail(db, defaultSiteKey, assetID)
        : null

      // Get location details
      const locationDetail: Location | null = await Util.getLocationDetail(
        db,
        defaultSiteKey,
        craftRecord.locationID
      )

      //Get all data related to SiteKey
      this.siteKeyData = await Util.getSiteKey(db, defaultSiteKey)

      // Get companies
      this.getCompanies()

      // Get craft details
      const craftDetailName = CraftType.getCraftTypeRecordString(
        craftRecord.craftType
      )
      if (
        has(this.siteKeyData, `customizations.craftDetails.${craftDetailName}`)
      )
        this.dynCraftDetails =
          this.siteKeyData.customizations.craftDetails[craftDetailName]

      const {
        id,
        craftType,
        refPath,
        title,
        description,
        locationID,
        timestampRecordCreated,
        latitude,
        longitude,
        numClosedTasks,
        numOpenTasks,
        craftDetails,
      } = craftRecord
      const result = await Promise.all([
        userDetail,
        locationDetail,
        assetDetail,
      ])

      this.$store.commit("firetableModule/stopLoading")

      return {
        id,
        craftType,
        refPath,
        title,
        description,
        assetName: result[2] ? result[2].title : "",
        assetID,
        locationName: result[1] ? result[1].title : "",
        locationID,
        timestampRecordCreated,
        latitude,
        longitude,
        numClosedTasks,
        numOpenTasks,
        craftDetails,
        createdBy: result[0] ? result[0].displayName : "",
        profilePhoto: result[0]
          ? result[0].userPhoto_URL
            ? result[0].userPhoto_URL
            : "/image/user-logo.png"
          : "/image/user-logo.png",
      }
    },

    async buildTaskList(otherTasks: Tasks[]) {
      const otherTasksLocal = otherTasks ? otherTasks : []
      try {
        this.loadingTaskList = true
        const { craftRecordTaskList } = this.$store.state.firetableModule
        const tasks = []
        const combinedTasks = [...otherTasksLocal, ...craftRecordTaskList]
        for (const task of combinedTasks) {
          task.assignedCompany = await Util.getSiteKeyCompanyDetail(
            db,
            this.defaultSiteKey,
            task.assignedCompanyID
          )
          tasks.push(task)
        }
        this.loadingTaskList = false
        return tasks
      } catch (error) {
        this.loadingTaskList = false
        Util.errorMessage(error)
        return []
      }
    },

    async updateCraftRecord(data) {
      const { rootUserData } = this.$store.state.firetableModule
      if (!rootUserData) return null

      data = {
        ...data,
        timestampLastModified: firebase.firestore.FieldValue.serverTimestamp(),
        lastModifiedBy: `${rootUserData.id}`,
      }

      this.loading = true

      try {
        //Get all data related to SiteKey
        const { defaultSiteKey } = rootUserData
        this.siteKeyData = await Util.getSiteKey(db, defaultSiteKey)
        const craftDetailName = CraftType.getCraftTypeRecordString(
          this.CRData.craftType
        )
        // Save craftDetails by calling the cloud function (if applies)
        if (
          has(
            this.siteKeyData,
            `customizations.craftDetails.${craftDetailName}`
          )
        ) {
          const saveCraftDetails = firebase
            .functions()
            .httpsCallable("updateCraftDetails")
          const refPath = `siteKeys/${defaultSiteKey}/parentRecords/${this.CRData.id}`
          await saveCraftDetails({ ...this.dynValues, refPath })
        }

        // Save Craft Record
        await db.doc(this.CRData.refPath).update(data)
        this.$store.commit("firetableModule/setSuccess", string.craftUpdated)
        this.setEditCR(false)
        this.loading = false
      } catch (error) {
        Util.errorMessage(error.message)
        this.loading = false
      }
    },

    async updateLocation(location) {
      await Util.updateLocation(db, this.CRData.refPath, location)
    },

    closeDialog() {
      this.confirmDialog = {
        show: false,
        title: "",
        message: "",
        cancelText: "",
        okText: "",
      }
    },

    confirmDeleteRecord() {
      const open = this.CRData.numOpenTasks
      const closed = this.CRData.numClosedTasks
      this.confirmDialog = {
        show: true,
        title: "Delete Craft Record?",
        message: `This Craft Record and all of its tasks (${open} open and ${closed} closed) will be deleted. Continue?`,
        cancelText: "Cancel",
        okText: "Delete",
      }
    },

    async deleteParentRecord() {
      try {
        this.loading = true
        const deleteParentRecord = firebase
          .functions()
          .httpsCallable("deleteParentRecord")
        await deleteParentRecord({ refPath: this.CRData.refPath })
        this.$store.commit("firetableModule/setSuccess", string.craftDeleted)
        this.$router.push("/craft-list/10")
      } catch (error) {
        if (["permission-denied", "PERMISSION_DENIED"].includes(error.code)) {
          this.snackbar = true
          this.snackbarText =
            "You are unable to delete this craft record with your current user permissions."
          this.snackBarColor = "error-dark"
        }
      } finally {
        this.loading = false
      }
    },

    getMultiCraftRecordParentRecords() {
      const { multiCraftRecord } = this.$store.state.firetableModule
      if (multiCraftRecord) {
        this.$store.dispatch("firetableModule/queryMultiCraftParentRecords")
      }
    },
    getMultiCraftRecordTasks() {
      const { multiCraftRecord } = this.$store.state.firetableModule
      if (multiCraftRecord) {
        this.$store.dispatch("firetableModule/queryMultiCraftTasks")
      }
    },
  },
  components: {
    gallery: Gallery,
    "task-card": TaskCard,
    "craft-record-drawer": CRDrawer,
    "confirm-dialog": ConfirmDialog,
  },
}
